import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection, $isRangeSelection, FORMAT_TEXT_COMMAND } from 'lexical';
import './FloatingToolbar.css';
import {
  $isParentElementRTL,
  $wrapNodes,
  $isAtNodeEnd
} from "@lexical/selection";

import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  $createParagraphNode,
  $getNodeByKey
} from "lexical";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode
} from "@lexical/list";
import { createPortal } from "react-dom";
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode
} from "@lexical/rich-text";
import {
  $createCodeNode,
  $isCodeNode,
  getDefaultCodeLanguage,
  getCodeLanguages
} from "@lexical/code";


const FloatingToolbar = ({ isAICollapsed, setIsAICollapsed, onTextSelection  }) => {
  const [editor] = useLexicalComposerContext();
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const viewportWidth = document.documentElement.clientWidth;
  const toolbarWidth = 200; // Approximate width of your toolbar
  const [blockType, setBlockType] = useState("paragraph");
  const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] = useState(
      false
    );

  const [isRTL, setIsRTL] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isCode, setIsCode] = useState(false);

  const [selectedElementKey, setSelectedElementKey] = useState(null);
  const [codeLanguage, setCodeLanguage] = useState("");
  const LowPriority = 1;
  const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);




  function getSelectedNode(selection) {
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
      return anchorNode;
    }
    const isBackward = selection.isBackward();
    if (isBackward) {
      return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    } else {
      return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
    }
  }
  function Divider() {
    return <div className="divider" />;
  }

  const updateToolbar = useCallback(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const anchorNode = selection.anchor.getNode();
        const element =
          anchorNode.getKey() === "root"
            ? anchorNode
            : anchorNode.getTopLevelElementOrThrow();
        const elementKey = element.getKey();
        const elementDOM = editor.getElementByKey(elementKey);
        if (elementDOM !== null) {
          setSelectedElementKey(elementKey);
          if ($isListNode(element)) {
            const parentList = $getNearestNodeOfType(anchorNode, ListNode);
            const type = parentList ? parentList.getTag() : element.getTag();
            setBlockType(type);
          } else {
            const type = $isHeadingNode(element)
              ? element.getTag()
              : element.getType();
            setBlockType(type);
            if ($isCodeNode(element)) {
              setCodeLanguage(element.getLanguage() || getDefaultCodeLanguage());
            }
          }
        }
        // Update text format
        setIsBold(selection.hasFormat("bold"));
        setIsItalic(selection.hasFormat("italic"));
        setIsUnderline(selection.hasFormat("underline"));
        setIsStrikethrough(selection.hasFormat("strikethrough"));
        setIsCode(selection.hasFormat("code"));
        setIsRTL($isParentElementRTL(selection));

        // Update links
        const node = getSelectedNode(selection);
        const parent = node.getParent();
        if ($isLinkNode(parent) || $isLinkNode(node)) {
          setIsLink(true);
        } else {
          setIsLink(false);
        }

        const selectedText = selection.getTextContent();
        onTextSelection(selectedText);
      }
    }, [editor, onTextSelection]);

  const formatLargeHeading = () => {
      if (blockType !== "h1") {
        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            $wrapNodes(selection, () => $createHeadingNode("h1"));
          }
        });
      }
      setShowBlockOptionsDropDown(false);
    };

  useEffect(() => {
    const updateToolbarVisibility = (editorState) => {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection) && !selection.isCollapsed()) {
          const nativeSelection = window.getSelection();
          if (nativeSelection.rangeCount > 0) {
            const range = nativeSelection.getRangeAt(0);
            const rect = range.getBoundingClientRect();
            let left = rect.left;
            if (left + toolbarWidth > viewportWidth) {
              left = viewportWidth - toolbarWidth;
            }
            setPosition({ top: rect.bottom + window.scrollY, left: left + window.scrollX });
            setIsVisible(true);
          }
        } else {
          setIsVisible(false);
        }
      });
    };

    return editor.registerUpdateListener(updateToolbarVisibility);
  }, [editor]);

  const applyFormat = (formatType) => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, formatType);
  };
  useEffect(() => {
        return mergeRegister(
          editor.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {
              updateToolbar();
            });
          }),
          editor.registerCommand(
            SELECTION_CHANGE_COMMAND,
            (_payload, newEditor) => {
              updateToolbar();
              return false;
            },
            LowPriority
          ),
          editor.registerCommand(
            CAN_UNDO_COMMAND,
            (payload) => {
              setCanUndo(payload);
              return false;
            },
            LowPriority
          ),
          editor.registerCommand(
            CAN_REDO_COMMAND,
            (payload) => {
              setCanRedo(payload);
              return false;
            },
            LowPriority
          )
        );
      }, [editor, updateToolbar]);

  if (!isVisible) return null;

  const onAIClick = () => {
    setIsAICollapsed(!isAICollapsed); // Toggles the state
  };




  return (
    <div
        className="floating-toolbar"
        style={{ top: `${position.top}px`, left: `${position.left}px` }}
      >
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
        }}
        className={"toolbar-item spaced " + (isBold ? "active" : "")}
        aria-label="Format Bold"
      >
        <i className="format bold" />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
        }}
        className={"toolbar-item spaced " + (isItalic ? "active" : "")}
        aria-label="Format Italics"
      >
        <i className="format italic" />
      </button>


      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
        }}
        className={"toolbar-item spaced " + (isUnderline ? "active" : "")}
        aria-label="Format Underline"
      >
        <i className="format underline" />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
        }}
        className={
          "toolbar-item spaced " + (isStrikethrough ? "active" : "")
        }
        aria-label="Format Strikethrough"
      >
        <i className="format strikethrough" />
      </button>

      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
        }}
        className="toolbar-item spaced"
        aria-label="Left Align"
      >
        <i className="format left-align" />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
        }}
        className="toolbar-item spaced"
        aria-label="Center Align"
      >
        <i className="format center-align" />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
        }}
        className="toolbar-item spaced"
        aria-label="Right Align"
      >
        <i className="format right-align" />
      </button>
      <Divider />

      <button
        onClick={onAIClick} // Use the passed function here
        className="toolbar-item spaced"
        aria-label="AI Icon"
      >
        <i className="format ai-icon" /> {/* Change the icon to represent opening a sidebar */}
      </button>


    </div>
  );
};

export default FloatingToolbar;

import React, { useState, useRef } from 'react';
import axios from 'axios';
import LoadingBar from 'react-top-loading-bar';

function GenerateComplianceMatrix({ onMatrixCreated, currentRFP, apiKey, setSelectedTab, setComplianceMatrixStreamed, selectedFile, handleFileUploadMatrix, setComplianceMatrixStreaming, selectedRefFiles }) {
  const [isLoading, setIsLoading] = useState(false);
  const loadingBarRef = useRef(null);


  const ENV = process.env.NODE_ENV; // 'test' or 'live'

  // Select the URL based on the environment
  const BASE_URL = window.location.hostname === 'localhost' ? process.env.REACT_APP_TEST_URL : process.env.REACT_APP_LIVE_URL;



  const handleGenerateMatrix = async () => {
    setIsLoading(true);
    handleFileUploadMatrix();
    setComplianceMatrixStreamed('');
    setComplianceMatrixStreaming(true);
    setSelectedTab('viewComplianceMatrix');
    if (loadingBarRef.current) {
      loadingBarRef.current.continuousStart();
    }

    try {

        const formData = new FormData();
        formData.append('currentRFP', currentRFP); // Ensure currentRFP is handled correctly, possibly as a file

        //console.log("Sending POST request to /govex/generate-compliance-matrix-stream");
        const streamResponse = await fetch(`${BASE_URL}/govex/generate-compliance-matrix-stream`, {
            method: 'POST',
            headers: {
                'X-API-KEY': apiKey,
            },
            body: formData,
        });

        if (!streamResponse.ok) {
            throw new Error(`Stream response was not ok: ${streamResponse.statusText}`);
        }

        const reader = streamResponse.body.getReader();
        const decoder = new TextDecoder();

        let completeComplianceMatrix = '';

        while (true) {
            const { value, done } = await reader.read();
            if (done) {
                console.log("Streaming complete");
                break;
            }
            const chunk = decoder.decode(value, { stream: true });
            //console.log("Chunk received:", chunk);
            completeComplianceMatrix += chunk;
            setComplianceMatrixStreamed((prevContent) => prevContent + chunk); // Update the parent component's state with the new chunk
        }

        const formData2 = new FormData();
        formData2.append('currentRFP', currentRFP); // Ensure currentRFP is handled correctly, possibly as a file
        formData2.append('complianceMatrix', completeComplianceMatrix);

        // Assuming formData2 is correctly populated with your 'currentRFP' and 'complianceMatrix'
        console.log("Sending POST request to /govex/analyze-compliance-matrix-accuracy");
        const accuracyResponse = await fetch(`${BASE_URL}/govex/analyze-compliance-matrix-accuracy`, {
            method: 'POST',
            body: formData2,
        });

        if (accuracyResponse.ok) {
            const responseData = await accuracyResponse.json(); // Parse the JSON in the response
            console.log(responseData.accuracy);
            console.log(responseData.summary);

            onMatrixCreated(completeComplianceMatrix, responseData.accuracy, responseData.summary);
        } else {
            console.error('Error fetching accuracy and summary:', accuracyResponse.statusText);
            // Handle HTTP error responses (e.g., 400, 401, 500, etc.)
            onMatrixCreated(completeComplianceMatrix, 0, "error processing compliance matrix");
        }



    } catch (error) {
        console.error('Error generating compliance matrix:', error);
    } finally {
        if (loadingBarRef.current) {
            loadingBarRef.current.complete();
        }
        setIsLoading(false);
    }

  };

  return (
    <div style={{ marginTop: '10px' }}>
      <button onClick={() => {
                    setComplianceMatrixStreaming(true);
                    handleGenerateMatrix();
                  }}
              disabled={isLoading || !selectedFile || !Object.values(selectedRefFiles).some(isSelected => isSelected)}>
          {isLoading ? 'Generating...' : 'Generate Compliance Matrix'}
      </button>

      <LoadingBar color="#f11946" ref={loadingBarRef} />
    </div>
  );
}

export default GenerateComplianceMatrix;

import React, { useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams if you're using React Router for routing
import './ReferralLandingPage.css';

const CustomPricing = () => {
  // Assuming you're using React Router, 'referralCode' will match the URL parameter
  let { referralCode } = useParams();

  const [pricingPeriod, setPricingPeriod] = useState('monthly');
  const prices = {
    basic: {
      monthly: 750, //price id: price_1OghMoCaD963jWWCuP6K0PE2
      annual: 8250, //price id: price_1OghNdCaD963jWWCEAvUgzCK
    },
    innovator: {
      monthly: 999, //price id: price_1Ogfy6CaD963jWWCdksrjy5N
      annual: 10000, //price id: price_1OgfzDCaD963jWWCP6YX9adp
    },
    unlimited: {
      monthly: 2500, //price id: price_1Ogfy6CaD963jWWCdksrjy5N
      annual: 25000, //price id: price_1OgfzDCaD963jWWCP6YX9adp
    },
    custom2: {
      monthly: 200, //price id: price_1Ogfy6CaD963jWWCdksrjy5N
      annual: 2000, //price id: price_1OgfzDCaD963jWWCP6YX9adp
    },
    // Enterprise pricing can be added similarly
  };

  const ENV = process.env.NODE_ENV; // 'test' or 'live'

  // Select the URL based on the environment
  const BASE_URL = window.location.hostname === 'localhost' ? process.env.REACT_APP_TEST_URL : process.env.REACT_APP_LIVE_URL;

  return (
    <div className="referral-landing-page">
      <div className="pricing-section" id="Pricing">
              <div
                className="pricing-section-text-div">
                <h2 className="pricing-section-title">Start making <span className="pricing-section-smart">smarter</span> decisions,</h2>
                <span className="pricing-section-span">Choose a plan</span>
              </div>
              <div className="pricing-period-tabs">
                <button
                    className={`billing-cycle-option ${pricingPeriod === 'monthly' ? 'selected' : ''}`}
                    onClick={() => setPricingPeriod('monthly')}>
                    Monthly
                  </button>
                  <button
                    className={`billing-cycle-option ${pricingPeriod === 'annual' ? 'selected' : ''}`}
                    onClick={() => setPricingPeriod('annual')}>
                    Annual
                  </button>
              </div>
              <div
                className="pricing-cards-container">



                <div className="pricing-card growth popular">
                  <div className="most-popular-tag">MOST POPULAR</div>
                  <h3 className="h3-most-popular">Unlimited Tier</h3>
                  <div className="price-container">
                    <p className="price">
                      {pricingPeriod === 'monthly' ?
                        `$${prices.unlimited.monthly.toLocaleString()}/mo` :
                        `$${prices.unlimited.annual.toLocaleString()}/yr`}
                    </p>

                    {pricingPeriod === 'annual' && (
                      <p className="savings">
                        Save ${(prices.unlimited.monthly * 12 - prices.unlimited.annual).toLocaleString()} with annual billing
                      </p>
                    )}
                  </div>

                  <ul className="pricing-features-list">
                    <li>Compliance Matrix Generator</li>
                    <li>Enhanced Outline Generator</li>
                    <li>Advanced First Draft Generator</li>
                    <li>Customizable Personal Database</li>
                    <li>Unlimited Volume Drafts</li>
                  </ul>
                  <div class="conic"></div>
                  <form action={`${BASE_URL}/create-checkout-session`} method="POST">
                    <input type="hidden" name="productTier" value="unlimited" />
                    <input type="hidden" name="billingCycle" value={pricingPeriod} />
                    <input type="hidden" name="referralCode" value={referralCode} />
                    <button className="start-trial-popular" type="submit">Get Started ➾</button>
                  </form>
                </div>

              </div>
            </div>
    </div>
  );
};

export default CustomPricing;

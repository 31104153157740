import React from 'react';
import './HowToUseSBIRDiagram.css';

function HowToUseSBIRDiagram() {
  return (
    <div className="diagramContainer">
      <iframe src="https://whimsical.com/embed/QTioD6PT4a5aoFMaK79Ecy@FNpptVQ16EWRKsEqLd5VFuxo8e4UW"></iframe>
      
    </div>
  );
}

export default HowToUseSBIRDiagram;

import React, { useRef, useEffect, useState } from 'react';
import './Home.css';
import * as THREE from 'three';
import Slider from 'react-slick';
import ProductHighlight from './ProductHighlight';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';
import SimpleModal from './SimpleModal'; // Import the modal component
import NewsletterPopup from "./NewsletterPopup";

// Rest of your code

const ENV = process.env.NODE_ENV; // 'test' or 'live'

// Select the URL based on the environment
const BASE_URL = window.location.hostname === 'localhost' ? process.env.REACT_APP_TEST_URL : process.env.REACT_APP_LIVE_URL;

function Home() {
  const containerRef = useRef();
  const partnerLogos = [
    '/images/RFP.png',
    '/images/RFI.png',
    '/images/RFQ.png',
    '/images/SBIR.png',
    '/images/STTR.png',
    '/images/BAA.png',
    '/images/FOA.png',

  ];
  const partnerLinks = [
    'https://govexai.com/',
    'https://govexai.com/',
    'https://govexai.com/',
    'https://govexai.com/',
    'https://govexai.com/',
    'https://govexai.com/',
    'https://govexai.com/'
  ];
  const intervalTime = 2000;
    function getPartnerLink(index) {
      // Replace this with the actual URLs for your partners
      return partnerLinks[index];
    }
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000, // Adjust the speed of the slide transition
    autoplaySpeed: 0, // Set to 0 for a continuous scroll without pause
    cssEase: "linear", // Ensures a smooth scrolling effect
    arrows: false,
    pauseOnHover: false,
    useTransform: true
  };


  const [featuresSectionTitleRef, featuresSectionTitleInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [featuresSectionSpanRef, featuresSectionSpanInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [featuresSectionPRef, featuresSectionPInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [featuresSectionContentImageDivRef, featuresSectionContentImageDivInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [featuresSectionContentTextH1Ref, featuresSectionContentTextH1InView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [featuresSectionContentTextPRef, featuresSectionContentTextPInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [featuresSectionContentDivHighlights1Ref, featuresSectionContentDivHighlights1InView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [featuresSectionContentDivHighlights2Ref, featuresSectionContentDivHighlights2InView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [featuresSectionContentDiv2TextH1Ref, featuresSectionContentDiv2TextH1InView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [featuresSectionContentDiv2TextPRef, featuresSectionContentDiv2TextPInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [featuresSectionContentDiv2ImageDivRef, featuresSectionContentDiv2ImageDivInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [testimonialsSectionTextDivRef, testimonialsSectionTextDivInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [testimonialsSectionContentTextDiv1Ref, testimonialsSectionContentTextDiv1InView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [testimonialsSectionContentTextDiv2Ref, testimonialsSectionContentTextDiv2InView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [pricingSectionTextDivRef, pricingSectionTextDivInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [pricingCardsContainerRef, pricingCardsContainerInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [newCapabilitiesSectionRef, newCapabilitiesSectionInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [SBIRImageDivRef, SBIRImageDivInView] = useInView({ triggerOnce: true, threshold: 0.1 });

  const [isOpen, setIsOpen] = useState(true);
    const [email, setEmail] = useState("");


  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const [pricingPeriod, setPricingPeriod] = useState('monthly');
  const prices = {
    basic: {
      monthly: 750, //price id: price_1OghMoCaD963jWWCuP6K0PE2
      annual: 8250, //price id: price_1OghNdCaD963jWWCEAvUgzCK
    },
    innovator: {
      monthly: 999, //price id: price_1Ogfy6CaD963jWWCdksrjy5N
      annual: 10000, //price id: price_1OgfzDCaD963jWWCP6YX9adp
    },
    base: {
          monthly: 99.99, //price id: price_1OghMoCaD963jWWCuP6K0PE2
          annual: 999.99, //price id: price_1OghNdCaD963jWWCEAvUgzCK
        },
    // Enterprise pricing can be added similarly
  };


  useEffect(() => {
      const script = document.createElement('script');
      script.src = "https://assets.calendly.com/assets/external/widget.js";
      script.async = true;
      document.body.appendChild(script);
      console.log(BASE_URL);

      return () => {
        document.body.removeChild(script);
      };
    }, []);

  useEffect(() => {
      // Parse the search query string
      const queryParams = new URLSearchParams(location.search);
      // Check if 'success=true' is present
      if (queryParams.get('success') === 'true') {
          setShowModal(true);
      }
  }, [location]); // Effect runs when location changes

  const handleCloseModal = () => {
      setShowModal(false);
  };
  const scrollToSection = (event, sectionId) => {
      event.preventDefault(); // Prevent default anchor link behavior
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        // Fallback for when the section ID is not found; navigate to the homepage
        // This is useful if the Navbar is used on other pages and the target section doesn't exist there
        navigate('/'); // Navigate to the homepage first
        setTimeout(() => {
          // Wait for the navigation to complete and the page to load
          const section = document.getElementById(sectionId);
          if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 0);
      }
    };



  return (
   <div className="home-div">
   {showModal && <SimpleModal onClose={handleCloseModal} />}
    <section className="home-section">
      <div className="hero-section">
        <div className="home-content">
          <div className="home-text">
            <h1><span>Streamline</span> Your Proposal Process with AI</h1>
            <p>Revolutionize your grant applications with GovEx. Transform complex solicitations into comprehensive drafts in minutes, tailored for SBIRs, STTRs, BAAs, and more. Elevate your success rate in securing funding and contracts.</p>
            <Link to="/govEx-proposal">
              <button className="explore-button" onClick={(e) => scrollToSection(e, 'Pricing')}>Book a Demo</button>
            </Link>
          </div>
          <div className="image-container">
            <div className="image-container-inside in">
              <img src="/images/Dashboard-screenshot-1.png" alt="Descriptive Alt Text" />
            </div>
          </div>
        </div>
        {/*
        <div className="our-partners-section">
          <h2 className="our-partners-title">Already trusted by industry leaders</h2>
          <div className="slider-container">
            <Slider {...settings}>
              {partnerLogos.map((logo, index) => (
                <div key={index} className="slide-item">
                  <a href={getPartnerLink(index)} target="_blank" rel="noopener noreferrer">
                    <img src={logo} alt={`Partner Logo ${index}`} className="partner-logo" />
                  </a>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        */}
        <div className="our-partners-section">
          <h2 className="our-partners-title">Works well with</h2>
          <div className="slider-container">
            <Slider {...settings}>
              {partnerLogos.map((logo, index) => (
                <div key={index} className="slide-item">
                  <a href={getPartnerLink(index)} target="_blank" rel="noopener noreferrer">
                    <img src={logo} alt={`Partner Logo ${index}`} className="partner-logo" />
                  </a>
                </div>
              ))}
            </Slider>
          </div>
        </div>

      </div>
      <div className="features-section" id="Proposal-Writer">
        <div
          className={`features-section-text-div ${featuresSectionTitleInView ? 'visible' : ''}`}
          ref={featuresSectionTitleRef}>
          <h1 className="features-section-title">The Future of Proposal Generation</h1>
        </div>
        <span
          ref={featuresSectionSpanRef}
          className={`features-section-span ${featuresSectionSpanInView ? 'visible' : ''}`}>
          AI-Driven, Accurate, and Agile
        </span>

        <p
          ref={featuresSectionPRef}
          className={`features-section-p ${featuresSectionPInView ? 'visible' : ''}`}>
          Revolutionize your proposal process - our AI generator tailors each document to your specific needs, ensuring a personalized touch every time.
        </p>

        <div className="features-section-content-div">
          <div
            ref={featuresSectionContentImageDivRef}
            className={`features-section-content-image-div ${featuresSectionContentImageDivInView ? 'visible' : ''}`}>
            <img src="/images/dashboard-headers-PC-1.png" alt="Descriptive Alt Text" />
          </div>
          <div className="features-section-content-text-div">
            <h1
              ref={featuresSectionContentTextH1Ref}
              className={`features-section-content-text-h1 ${featuresSectionContentTextH1InView ? 'visible' : ''}`}>
              <span>Fast-Track</span> Your Proposals
            </h1>
            <p
              ref={featuresSectionContentTextPRef}
              className={`features-section-content-text-p ${featuresSectionContentTextPInView ? 'visible' : ''}`}>
              In today's fast-paced business environment, time is a commodity, and efficiency is the key to success. Our AI Proposal Writer is designed to transform this challenge into an opportunity. Leveraging the latest advancements in artificial intelligence, this tool streamlines the proposal writing process, turning tedious hours into mere minutes.
            </p>
            <div className="features-section-content-div-in-text-div">
              <div
                ref={featuresSectionContentDivHighlights1Ref}
                className={`features-section-content-div-highlights ${featuresSectionContentDivHighlights1InView ? 'visible' : ''}`}>
                <div className="features-section-content-div-icon">
                  <img src="/images/db-icon.png" alt="Descriptive Alt Text" />
                </div>
                <h4>Personalized, Secure, Manageable Database</h4>
                <p>Effortlessly add or remove files from your database to continuously refine and personalize the AI's proposal generation to your specific needs and preferences.</p>
              </div>
              <div
                ref={featuresSectionContentDivHighlights2Ref}
                className={`features-section-content-div-highlights ${featuresSectionContentDivHighlights2InView ? 'visible' : ''}`}>
                <div className="features-section-content-div-icon">
                  <img src="/images/ai-dashboard-icon.png" alt="Descriptive Alt Text" />
                </div>
                <h4>AI Assistant: Your Partner in Rapid Proposal Editing</h4>
                <p>Streamline your proposal editing with an AI Assistant that rapidly enhances content for precision and quality.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="features-section-content-div-2">
          <div
            ref={featuresSectionContentDiv2TextH1Ref}
            className={`features-section-content-text-div-2 ${featuresSectionContentDiv2TextH1InView ? 'visible' : ''}`}>
            <h1>Tailor-made <span>Proposals</span>, just for your business</h1>
            <p
              ref={featuresSectionContentDiv2TextPRef}
              className={`${featuresSectionContentDiv2TextPInView ? 'visible' : ''}`}>
              Experience personalized proposal creation, meticulously crafted by AI to align precisely with your unique business needs and goals.
            </p>
          </div>

          <div
            ref={featuresSectionContentDiv2ImageDivRef}
            className={`features-section-content-image-div-2 ${featuresSectionContentDiv2ImageDivInView ? 'visible' : ''}`}>
            <img src="/images/manage_db_screenshot_blue_corner.png" alt="Descriptive Alt Text" />
          </div>
        </div>


        <div className={`features-section-new-capabilities-wrapper ${newCapabilitiesSectionInView ? 'visible' : ''}`} ref={newCapabilitiesSectionRef}>
          <div className="features-section-new-capabilities-image">
            <img src="/images/Comparison_transparent.png" alt="New Capabilities Image" />
          </div>
          <div className="features-section-new-capabilities">
            <h2>Why <span>GovEx AI</span>?</h2>
            <p>We are the first app/company to offer comprehensive support for responding to not just RFPs, RFIs, and RFQs, but also SBIRs, FOAs, BAAs, and STTRs.</p>
            <ul>
              <li>SBIR (Small Business Innovation Research): Our tool extracts and structures detailed requirements such as Topics Areas, Funding, Deadlines and more. With that, users can then create proposal drafts to respond to specific topics. Making it easier for small businesses to tackle these opportunities.</li>
              <li>FOA (Funding Opportunity Announcements): Identify and respond to funding opportunities with precision and efficiency.</li>
              <li>BAA (Broad Agency Announcements): Streamline the process of responding to BAAs with tailored proposals that meet specific agency requirements.</li>
              <li>STTR (Small Business Technology Transfer): Facilitate partnerships between small businesses and research institutions for technological advancements.</li>
            </ul>
          </div>
        </div>

        <div
          ref={SBIRImageDivRef}
          className={`SBIRImageDiv ${SBIRImageDivInView ? 'visible' : ''}`}>
          <img src="/images/SBIR-ss.png" alt="Descriptive Alt Text" />
        </div>

      </div>


      <div className="testimonials-section" id="Customer-Stories">
        <div
          ref={testimonialsSectionTextDivRef}
          className={`testimonials-section-text-div ${testimonialsSectionTextDivInView ? 'visible' : ''}`}>

          <h1 className="testimonials-section-title">Hear what some of our clients have to say about GovEx AI</h1>
        </div>

        <div className="testimonials-section-content-div">
            <div
              ref={testimonialsSectionContentTextDiv1Ref}
              className={`testimonials-section-content-text-div ${testimonialsSectionContentTextDiv1InView ? 'visible' : ''}`}>

            <div className="testimonials-section-content-text-profile-div">
              <img src="/images/stock-1.jpg" alt="Descriptive Alt Text" />
            </div>
            <p>“The GovEx AI Proposal Writer has significantly streamlined our proposal process. It's user-friendly and remarkably efficient, turning hours of work into just a few minutes.”</p>
            <div className="testimonial-section-profile-name-title">
              <span>Justin Lohneis</span>
              <p>Account Executive</p>
            </div>
          </div>


          <div
            ref={testimonialsSectionContentTextDiv2Ref}
            className={`testimonials-section-content-text-div ${testimonialsSectionContentTextDiv2InView ? 'visible' : ''}`}>
            <div className="testimonials-section-content-text-profile-div">
              <img src="/images/neelie.jpg" alt="Descriptive Alt Text" />
            </div>
            <p>“GovEx AI not only simplifies the start of our proposal process but it's compliance matrix feature is a game changer. It quickly lays the groundwork, freeing us up from the initial drafting to focus more on reviewing and refining.”</p>
            <div className="testimonial-section-profile-name-title">
              <span>Neelie Rosson</span>
              <p>Proposal Coordinator</p>
            </div>
          </div>
        </div>
      </div>

      <NewsletterPopup />



      <div className="pricing-section-calendar" id="Pricing">
        {/*}
        <div
          ref={pricingSectionTextDivRef}
          className={`pricing-section-text-div ${pricingSectionTextDivInView ? 'visible' : ''}`}>
          <h2 className="pricing-section-title">Start making <span className="pricing-section-smart">smarter</span> decisions,</h2>
          <span className="pricing-section-span">Choose a plan</span>
        </div>
        */}
        <div
          ref={pricingSectionTextDivRef}
          className={`pricing-section-text-div-calendar ${pricingSectionTextDivInView ? 'visible' : ''}`}>
          <h2 className="pricing-section-title">Experience the <span className="pricing-section-smart">GovEx</span> Difference</h2>
          <span className="pricing-section-span">Ready to elevate your operations?</span>
        </div>

        {/*

        <div className="pricing-period-tabs">
          <button
              className={`billing-cycle-option ${pricingPeriod === 'monthly' ? 'selected' : ''}`}
              onClick={() => setPricingPeriod('monthly')}>
              Monthly
            </button>
            <button
              className={`billing-cycle-option ${pricingPeriod === 'annual' ? 'selected' : ''}`}
              onClick={() => setPricingPeriod('annual')}>
              Annual
            </button>
        </div>
        <div
          ref={pricingCardsContainerRef}
          className={`pricing-cards-container ${pricingCardsContainerInView ? 'visible' : ''}`}>
          {/*
          <div className="pricing-card hobby">
            <h3>Basic</h3>
            <div className="price-container">
              <p className="price">
                {pricingPeriod === 'monthly' ?
                  `$${prices.basic.monthly.toLocaleString()}/mo` :
                  `$${prices.basic.annual.toLocaleString()}/yr`}
              </p>

              {pricingPeriod === 'annual' && (
                <p className="savings">
                  Save ${(prices.basic.monthly * 12 - prices.basic.annual).toLocaleString()} with annual billing
                </p>
              )}
            </div>
            <ul className="pricing-features-list">
              <li>Enhanced Outline Generator</li>
              <li>Customizable Personal Database</li>
              <li>Advanced First Draft Generator</li>
              <li>Intuitive AI Editing Assistant</li>
              <li>Ten Volume Drafts Per Month</li>
            </ul>
            <form action={`${BASE_URL}/create-checkout-session`} method="POST">
              <input type="hidden" name="productTier" value="basic" />
              <input type="hidden" name="billingCycle" value={pricingPeriod} />
              <button className="start-trial-btn" type="submit">Get Started ➾</button>
            </form>


          </div>

          <div className="pricing-card growth popular">
            <div className="most-popular-tag">MOST POPULAR</div>
            <h3 className="h3-most-popular">Base Tier</h3>
            <div className="price-container">
              <p className="price">
                {pricingPeriod === 'monthly' ?
                  `$${prices.base.monthly.toLocaleString()}/mo` :
                  `$${prices.base.annual.toLocaleString()}/yr`}
              </p>

              {pricingPeriod === 'annual' && (
                <p className="savings">
                  Save ${(prices.innovator.monthly * 12 - prices.innovator.annual).toLocaleString()} with annual billing
                </p>
              )}
            </div>

            <ul className="pricing-features-list">
              <li>Enhanced Outline Generator</li>
              <li>Customizable Personal Database</li>
              <li>Advanced First Draft Generator</li>
              <li>Intuitive AI Editing Assistant</li>
              <li>Two Proposal Generations Per Month</li>
              <li>Four Volume Tokens Per Month</li>
            </ul>
            <div class="conic"></div>
            <form action={`${BASE_URL}/create-checkout-session`} method="POST">
              <input type="hidden" name="productTier" value="base" />
              <input type="hidden" name="billingCycle" value={pricingPeriod} />
              <button className="start-trial-popular" type="submit">Get Started ➾</button>
            </form>
          </div>
          <div className="pricing-card scale">
            <h3>Enterprise</h3>
            <div className="price-container">
                <p className="price">Contact us</p>
            </div>
            <ul className="pricing-features-list">
              <li>Everything in Base Tier, plus:</li>
              <li>Custom-Fit Pricing Solutions</li>
              <li>Real-Time Collaboration Tools</li>
              <li>Cloud-Based Auto-Save</li>
              <li>Interactive Content Features</li>
            </ul>
            <form action={"/contact"}>
              <button className="start-trial-btn" type="submit">Get Started ➾</button>
            </form>
          </div>
        </div>
        */}

        <div
          className="calendly-inline-widget"
          data-url="https://calendly.com/govexai/30min"
          style={{ minWidth: '100%', height: '700px' }}
          id="Contact"
        ></div>

      </div>


    </section>

   </div>
  );
}

export default Home;
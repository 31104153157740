import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './ArticlePreview.css'; // Import your CSS file for styling

const ArticlePreview = ({ post }) => {
  // Extract post data
  const { ID, featured_image, title, excerpt, date } = post;
  const [articleId] = useState(ID);

  // Check if the post has a category 'Gov'
  const isGovCategory = 'Gov' in post.categories;

  // Determine the CSS class to apply
  const previewClass = isGovCategory ? 'article-preview gov-category' : 'article-preview';

  // This function safely converts HTML entities to their corresponding characters
  const decodeHtmlEntities = (html) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };

  // Format the date to a human-readable format (e.g., "October 23, 2023")
  const formattedDate = new Date(date).toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const modifiedTitle = title
    .replace(/[\s,]+/g, '-')  // Replace spaces and commas with hyphens
    .replace(/&#8217;/g, "'") // Replace "&#8217;" with a single apostrophe
    .replace(/[^\w-\s']+/g, '')
    .toLowerCase();           // Convert to lowercase

  return (
    <div className={isGovCategory ? 'article-preview gov-category' : 'article-preview'}>
      {isGovCategory && <div className="gov-sticker">Gov Related</div>}
      <Link to={`/blog/${ID}/${modifiedTitle}`} state={{ articleId }} className="preview-link">
        {featured_image && (
            <img
                className="preview-image"
                alt="article header image"
                src={featured_image}
            />
        )}
        <p className="publication-date">{formattedDate}</p>
        <h1 className="preview-title">{decodeHtmlEntities(title)}</h1>
        <div
            className="preview-excerpt"
            dangerouslySetInnerHTML={{ __html: decodeHtmlEntities(excerpt) }}
        />
        <button className="read-more-btn">Read More</button>
      </Link>
    </div>
  );

};

export default ArticlePreview;

import React, { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import GenerateFirstDraft from './GenerateFirstDraft'; // Adjust the import path as necessary
import { $createTextNode, $createParagraphNode, $getRoot } from 'lexical';

const GenerateFirstDraftWithLexical = (props) => {
  const [editor] = useLexicalComposerContext();
  const { outlineHeaders, outlineTitle } = props; // Extract outlineHeaders and outlineTitle from props
  console.log('outlineContent:', outlineHeaders); // Log the outlineContent

  const handlePrepareData = () => {
    const editorState = editor.getEditorState();
    const editorStateTextString = editorState.read(() => $getRoot().getTextContent());

    // Use the original outlineHeaders from props if editorStateTextString is empty, null, or undefined
    const effectiveOutlineHeaders = editorStateTextString || outlineHeaders;

    // Pass the determined headers to GenerateFirstDraft as props
    return {
      outlineHeaders: effectiveOutlineHeaders, // Use effectiveOutlineHeaders which falls back to props' outlineHeaders if needed
      outlineTitle: outlineTitle
    };
  };

  // Merge the prepared data with other props
  const preparedProps = { ...props, ...handlePrepareData() };

  return <GenerateFirstDraft {...preparedProps} />;
};

export default GenerateFirstDraftWithLexical;
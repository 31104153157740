import './HowToUseGovExAISBIR.css';
import { Link } from 'react-router-dom';

function HowToUseGovExAISBIR() {
  return (
    <div className="guideContainerSBIR">
      <div className="demoLinkContainer">
        <a
          href="https://www.loom.com/share/7baf0546acfd4689b5b48f779a512a44?sid=aedfb8de-a3ba-4ca4-89c6-a561353e9fb4"
          target="_blank"
          rel="noopener noreferrer"
          className="demoLink"
        >
          🎥 Watch the Demo on How to Use the SBIR Module
        </a>
      </div>
      <h2 className="guideTitle">🚀 How to Use GovEx AI Proposal Writer</h2>
      <ol className="guideStepsSBIR">
        <li className="guideStepItem">
          <h3 className="guideStepTitle">Step 1: 🛠 Select a Workflow</h3>
          <p><strong>Starting Point:</strong> The first and most critical step in creating a successful proposal is selecting the right workflow. The workflow acts as the foundation for your entire proposal process on the GovEx AI Proposal Writer platform. It organizes your opportunities and serves as a central hub where all related files, progress, and data are stored.</p>
          <p><strong>Why This is Important:</strong> The workflow is the backbone of your proposal process. By selecting or creating a workflow, you ensure that all your efforts are organized and easily accessible. This structure allows you to track progress, manage documents, and maintain consistency throughout the proposal development cycle. Whether you're starting fresh or picking up where you left off, the workflow keeps everything streamlined and under control.</p>
          <p><strong>Action:</strong> You have two primary options when starting your proposal process:</p>
          <ul className="guideActionList">
            <li><strong>Create a New Workflow:</strong> If you're initiating a new proposal, begin by creating a new workflow. This involves entering a unique and descriptive name for your workflow, such as "Proposal for SBIR - 2024 - Company X." This naming convention helps you easily identify and manage your workflows, especially when working on multiple proposals simultaneously. After naming your workflow, click "Create New Proposal Process." This action generates a new workspace where all related activities, documents, and progress will be saved, providing a clean slate for your new proposal.</li>
            <li><strong>Choose an Existing Workflow:</strong> If you've previously started a proposal but haven't completed it, you can easily continue your work by selecting an existing workflow from the list. This feature is particularly useful when you need to pause and resume work, ensuring that no time is lost and that you can seamlessly pick up where you left off. All files, progress, and notes associated with the workflow will be immediately accessible, allowing you to maintain continuity and focus on completing the proposal efficiently.</li>
          </ul>
          <p><strong>Best Practices:</strong> We recommend always using clear and consistent naming conventions for your workflows to make them easily identifiable. Regularly save your progress within the workflow to ensure that all updates are recorded and can be accessed at any time.</p>
        </li>

        <li className="guideStepItem">
          <h3 className="guideStepTitle">Step 2: 📂 Manage Database</h3>
          <p><strong>Purpose:</strong> During onboarding, use the "Manage Database" section to upload important documents relevant to your company.</p>
          <p><strong>Actions:</strong> These documents could include whitepapers, resumes, product descriptions, and other materials that you might want the AI to reference when generating future proposals.</p>
          <ul className="guideActionList">
            <li><strong>Add Documents:</strong> Upload new, relevant documents to your company's database.</li>
            <li><strong>Remove Documents:</strong> Delete outdated or irrelevant documents to keep your database current.</li>
          </ul>
        </li>

        <li className="guideStepItem">
          <h3 className="guideStepTitle">Step 3: 📤 Upload Solicitation and Select Reference Files</h3>
          <p><strong>Uploading Documents:</strong> Start by clicking the upload button to add your solicitation document, whether it's an RFP, RFI, RFQ, or SBIR.</p>
          <p><strong>Select Reference Files:</strong> This step is crucial to the quality of the proposal the AI will generate. The AI needs relevant context about your company and products to craft a proposal that aligns perfectly with the solicitation's requirements.</p>
          <p><strong>Why It's Important:</strong> The solicitation outlines specific needs, challenges, and goals. For the AI to draft a proposal that effectively addresses these, it must understand the unique value your company brings to the table. This understanding is derived from the reference files you select.</p>
          <p><strong>Recommendation:</strong> At a minimum, we strongly recommend uploading the following documents:</p>
          <ul className="guideActionList">
            <li><strong>Product Description Overview:</strong> This document should provide detailed information about your products or services, including key features, benefits, and any competitive advantages. By including this, the AI can effectively highlight how your offerings directly address the solicitation's needs, making your proposal more compelling and targeted.</li>
            <li><strong>Company Overview:</strong> This document should offer a comprehensive view of your company, including its history, mission, core competencies, and past successes. Providing this context allows the AI to position your company as the ideal partner or vendor, leveraging your organization's strengths and experience to build trust and credibility in the proposal.</li>
          </ul>
          <p><strong>Synergy:</strong> By uploading these key documents, the AI can synergistically combine detailed product information with your company's overarching narrative. This ensures the proposal not only meets but exceeds the solicitation's requirements by showcasing how your technology and expertise uniquely solve the client's challenges.</p>
        </li>

        <li className="guideStepItem">
          <h3 className="guideStepTitle">Step 4: 🧠 Analyze Solicitation</h3>
          <p><strong>Action:</strong> After selecting the reference files, click the "Analyze" button at the bottom of the page to start the AI's analysis of the solicitation.</p>
          <p><strong>Outcome:</strong> The AI will review the solicitation and prepare the necessary data for the next steps.</p>
        </li>

        <li className="guideStepItem">
          <h3 className="guideStepTitle">Step 5: 🎯 Select Topics and Generate Proposal Structure</h3>
          <p><strong>Action:</strong> After the AI has analyzed the solicitation, it will provide a detailed breakdown of the various topics or sections that your proposal could address. This step is crucial because it allows you to focus your efforts on the specific areas that are most relevant to your strengths and the solicitation's requirements.</p>
          <p><strong>Choosing Topics:</strong> Carefully review the analysis results and select the topics that align best with your company's expertise, products, or services. This targeted approach ensures that your proposal is not only comprehensive but also highly relevant to the client's needs. By selecting the right topics, you position your proposal to effectively address the key challenges and goals outlined in the solicitation.</p>
          <p><strong>Generate Structure:</strong> Once you've selected the topics, click the "Generate Proposal Structure" button under the relevant application. This action initiates the AI's process of creating a tailored structure for your proposal. A popup will appear, prompting you to enter outline keywords. These keywords should be carefully chosen based on the content of the solicitation, particularly focusing on the sections that outline the client's requirements, objectives, and evaluation criteria.</p>
          <p><strong>Purpose:</strong> The AI uses the entered keywords to search the solicitation and pull all related tables of contents or outline sections. This process is designed to ensure that your proposal structure aligns closely with the solicitation's format and expectations. If the AI finds matching tables of contents, you will be able to review and edit them to better fit your proposal strategy. If no relevant outlines are found, the AI will automatically generate a proposal structure, providing a solid foundation that you can customize further.</p>
        </li>

        <li className="guideStepItem">
          <h3 className="guideStepTitle">Step 6: ✏️ Edit Outline and Generate Draft</h3>
          <p><strong>Edit Outline:</strong> Review and edit the proposed outline for your proposal, ensuring it meets your needs.</p>
          <p><strong>Generate Draft:</strong> Once satisfied, click the "Generate Draft" button at the bottom of the page to create the proposal draft.</p>
        </li>

        <li className="guideStepItem">
          <h3 className="guideStepTitle">Step 7: 📄 Export Document</h3>
          <p><strong>Final Step:</strong> After the draft is generated, save and export the document by clicking the button at the top right of the editor toolbar.</p>
        </li>

        <li className="guideStepItem">
          <h3 className="guideStepTitle">Step 8: 🔄 Iterate as Needed</h3>
          <p><strong>Further Refinement:</strong> Return to the proposal structure section to create additional drafts for different volumes or make further edits as needed.</p>
        </li>
      </ol>
      <div className="diagramLinkContainer">
        <Link to="/diagram" className="diagramLink">
          📊 View SBIR Diagram
        </Link>
      </div>
    </div>
  );
}

export default HowToUseGovExAISBIR;

import React, { useEffect, useRef } from 'react';
import ExampleTheme from "./themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import "../styles.css"
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";

import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import TreeViewPlugin from "./plugins/TreeViewPlugin";
import ToolbarPluginSBIR from "./plugins/ToolbarPluginSBIR";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import {TablePlugin} from '@lexical/react/LexicalTablePlugin';

//import LexicalTableOfContents from '@lexical/react/LexicalTableOfContents';
import {TabIndentationPlugin} from '@lexical/react/LexicalTabIndentationPlugin';

import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { $createTextNode, $createParagraphNode, $getRoot } from 'lexical';
import { $createHeadingNode, $createQuoteNode } from '@lexical/rich-text';
import {$createListItemNode, $createListNode} from '@lexical/list';
import {$createLinkNode} from '@lexical/link';
import { ExportFile } from "./plugins/ExportFile";



import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";

function Placeholder() {
  return <div className="editor-placeholder">Enter some rich text...</div>;
}
function prepopulateEditor() {
  const root = $getRoot();
  if (root.getFirstChild() === null) {
    const heading = $createHeadingNode('h1');
    heading.append($createTextNode(''));
    root.append(heading);
    const paragraph = $createParagraphNode();
    paragraph.append($createTextNode('Your Outline Headers will go here for editing.'));
    root.append(paragraph);

    //const list = $createListNode('bullet');
    //list.append(
    //  $createListItemNode().append(
    //    $createTextNode(`This is a test `),
    //    $createLinkNode('https://govexai.com').append(
    //      $createTextNode('GovEx AI Home Page'),
    //    )
    //  )
    //);
    //const list = $createListNode('bullet');
    //list.append(
    //  $createListItemNode().append(
    //    $createTextNode(`This is a test `),
    //    $createLinkNode('https://govexai.com').append(
    //      $createTextNode('GovEx AI Home Page'),
    //    )
    //  )
    //);
    //root.append(list);
  }
}
const editorConfig = {
  editorState: prepopulateEditor,
  // The editor theme
  theme: ExampleTheme,
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode
  ]
};

export default function EditorSBIR({isAICollapsed, setIsAICollapsed, updateEditorContent, onAIClick, token, proposalProcessName, tag, handleResponseFromEditOutline, stage }) {
    const editorRef = useRef(null);
    //console.log("EF ", editorRef);
    useEffect(() => {
        //console.log("HERE");
        if (updateEditorContent && editorRef.current) {
          editorRef.current.update(() => {
            const root = $getRoot();
            root.clear();
            const paragraphNode = $createParagraphNode();
            paragraphNode.append($createTextNode("hello"));
            root.append(paragraphNode);
          });
        }
      }, [updateEditorContent]);


  return (
      <div className="editor-container">
        <ToolbarPluginSBIR
            isAICollapsed={isAICollapsed}
            setIsAICollapsed={setIsAICollapsed}
            token={token}
            proposalProcessName={proposalProcessName}
            tag={tag}
            handleResponseFromEditOutline={handleResponseFromEditOutline}
            stage={stage}
        />
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          <AutoFocusPlugin />
          <CodeHighlightPlugin />
          <ListPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          {/*<TreeViewPlugin /><LexicalTableOfContents />*/}
          <TabIndentationPlugin />
          <TablePlugin />

        </div>
      </div>
  );
}

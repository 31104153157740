import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import LoadingBar from 'react-top-loading-bar';
import { $createTextNode, $getRoot } from 'lexical';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

function GenerateFirstDraft({ onFirstDraftCreated, apiKey, setSelectedTab, setStreamedContent, companyName, outlineHeaders, outlineTitle, setOutlineContent, proposalProcessName, setFirstDraftSteaming, userID, volumeTag, rfpFile, numProposalsRFx, selectedRefFiles }) {

  const [isLoading, setIsLoading] = useState(false);
  const loadingBarRef = useRef(null);

  const ENV = process.env.NODE_ENV; // 'test' or 'live'

  // Select the URL based on the environment
  const BASE_URL = window.location.hostname === 'localhost' ? process.env.REACT_APP_TEST_URL : process.env.REACT_APP_LIVE_URL;

  // Extract only selected files' names into an array
  const selectedFilesArray = Object.entries(selectedRefFiles)
                                    .filter(([_, isSelected]) => isSelected)
                                    .map(([fileName, _]) => fileName);







  const handleReadContent = async () => {
      console.log("selected file rfp - ", rfpFile);
      console.log("selected ref files - ", selectedFilesArray);
      setStreamedContent('');
      setSelectedTab('viewGenerationFirstDraft');
      setFirstDraftSteaming(true);
      if (loadingBarRef.current) {
          loadingBarRef.current.continuousStart();
      }

      try {
          //console.log("outline title - ", outlineTitle)
          const formData = new FormData();
          formData.append('outlineHeaders', outlineHeaders);
          formData.append('outlineTitle', outlineTitle);
          formData.append('userID', userID);
          formData.append('rfpFile', rfpFile);
          selectedFilesArray.forEach(fileName => {
              formData.append('selectedRefFiles', fileName);
          });
          //console.log("outline headers: ". outlineHeaders);
          setOutlineContent(outlineHeaders);

          //console.log("Sending POST request to /govex/generate-proposal-stream");
          const streamResponse = await fetch(`${BASE_URL}/govex/generate-proposal-stream`, {
              method: 'POST',
              headers: {
                  'X-API-KEY': apiKey,
                  'X-Company-Name': companyName
              },
              body: formData,
          });

          if (!streamResponse.ok) {
              throw new Error(`Stream response was not ok: ${streamResponse.statusText}`);
          }

          //console.log("Stream response received, starting to process");
          const reader = streamResponse.body.getReader();
          const decoder = new TextDecoder();

          let completeProposalContent = '';

          while (true) {
              const { value, done } = await reader.read();
              if (done) {
                  console.log("Streaming complete");
                  break;
              }
              const chunk = decoder.decode(value, { stream: true });
              //console.log("Chunk received:", chunk);
              completeProposalContent += chunk;
              setStreamedContent((prevContent) => prevContent + chunk); // Update the parent component's state with the new chunk
          }

          // Now, send the complete proposal content to finalize the proposal
          const finalizeResponse = await fetch(`${BASE_URL}/govex/finalize-rfx-proposal`, {
              method: 'POST',
              headers: {
                  'X-API-KEY': apiKey,
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ proposalContent: completeProposalContent }),
          });

          if (!finalizeResponse.ok) {
              throw new Error(`Finalize response was not ok: ${finalizeResponse.statusText}`);
          }

          const finalizeData = await finalizeResponse.json();
          //console.log("Finalize response received:", finalizeData);




          axios.post(`${BASE_URL}/govex/convert`, completeProposalContent, {
            responseType: 'blob',
            headers: {
              'Content-Type': 'text/plain',
              'X-Proposal-Name': proposalProcessName,
            },
          })
          .then((response) => {

            const contentDisposition = response.headers['content-disposition'];
            let fileName = 'download.docx'; // Default filename if not found
            if (contentDisposition) {
              const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
              if (fileNameMatch && fileNameMatch.length > 1) {
                fileName = fileNameMatch[1];
              }
            }

            // Create a Blob object from the response data
            const blob = new Blob([response.data]);

            // Set the blob object to documentFile
            const file = new File([blob], fileName, { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }); // Specify the file type if known
            const documentFile = file; // Set the file to documentFile
            // Handle the response, e.g., updating state or UI based on finalizeData
            //console.log("vl tag: ", volumeTag);
            onFirstDraftCreated(completeProposalContent, finalizeData.new_num_proposals_rfx, documentFile, volumeTag, outlineHeaders);

          })
          .catch((error) => {
            console.error('Error downloading the document:', error);
          });






      } catch (error) {
          console.error('Error processing proposal:', error);
      } finally {
          if (loadingBarRef.current) {
              loadingBarRef.current.complete();
          }
          setIsLoading(false);
      }
  };



  function convertBase64ToBlob(base64) {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
  }

  return (
    <div>
      <button onClick={handleReadContent} disabled={isLoading || (numProposalsRFx == 0)}>
        {isLoading ? 'Generating...' : (numProposalsRFx == 0 ? 'You are out of tokens. Contact us to purchase more' : 'Create a new draft based on this outline')}
      </button>

      <LoadingBar color="#f11946" ref={loadingBarRef} />
    </div>
  );
}

export default GenerateFirstDraft;

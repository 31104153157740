import React, { useState } from 'react';
import './ReferralPage.css'; // Import your CSS file for styling

function ReferralPage(props) {
    // Extract referral code and number of referrals from props
    const { referralCode, numReferrals } = props;
    const [isCopied, setIsCopied] = useState(false);

    // Construct the referral link
    const referralLink = `https://govexai.com/referral/${referralCode}`;
    const copyReferralLink = () => {
        navigator.clipboard.writeText(referralLink).then(() => {
            // Indicate that link was copied
            setIsCopied(true);
            // Set the copied status to false after 2 seconds
            setTimeout(() => setIsCopied(false), 2000);
        });
    };


    return (
        <div className="referral-container">
            <h1 className="referral-title">Empower Your Network with AI</h1>
            <p className="referral-description">
                Enhance your business connections by sharing the power of AI-driven proposal writing. Introduce your colleagues to our platform, and enjoy exclusive rewards for both you and your referrals.
            </p>

            <div className="referral-steps">
                <div className="referral-step">
                    <h2 className="step-title">1. Share Your Exclusive Referral Link</h2>
                    <p className="step-description">
                        Distribute your unique referral link through email, LinkedIn, or direct message to provide your network with a special 20% discount for their first month.
                    </p>
                    <div className="referral-link-container">
                        <input type="text" value={referralLink} readOnly className="referral-link-input"/>
                        <button
                            className="copy-link-button"
                            onClick={copyReferralLink}
                        >
                            Copy Referral Link
                        </button>
                        {isCopied && <span className="copy-confirmation">Copied!</span>}
                    </div>

                </div>
                <div className="referral-step">
                    <h2 className="step-title">2. They Join Our Platform</h2>
                    <p className="step-description">
                        Your referrals sign up using your link and discover the efficiency of AI-assisted proposal writing, gaining an immediate advantage with a 20% discount on their initial subscription.
                    </p>
                </div>
                <div className="referral-step">
                    <h2 className="step-title">3. Rewards for Both</h2>
                    <p className="step-description">
                        Once their first month is activated, you receive a $150 reward as our token of appreciation. It’s our way of saying thank you for spreading the word.
                    </p>
                </div>
            </div>

            <p className="referral-note">So far, you've referred {numReferrals} professional{numReferrals === 1 ? '' : 's'} to our platform. The more colleagues you introduce, the more rewards you accumulate!</p>
        </div>
    );
}

export default ReferralPage;

import React, { useState } from 'react';
import './AISidebar.css';

const AISidebar = ({ isOpen, onClose, onTextChange, text, isAICollapsed, setIsAICollapsed }) => {




  // NODE_ENV=test in .env
  const ENV = process.env.NODE_ENV; // 'test' or 'live'

  // Select the URL based on the environment
  const BASE_URL = window.location.hostname === 'localhost' ? process.env.REACT_APP_TEST_URL : process.env.REACT_APP_LIVE_URL;
  const toggleAISidebar = () => {
    setIsAICollapsed(!isAICollapsed);
  };

  const [copySuccess, setCopySuccess] = useState(false);





  const [additionalText, setAdditionalText] = useState('');
  const [chatResponse, setChatResponse] = useState('');
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(chatResponse)
      .then(() => {
        // Handle successful copy
        console.log("Text copied to clipboard");
        setCopySuccess(true); // Indicate copy success

        // Optional: Reset copySuccess state after 2 seconds to revert styling
        setTimeout(() => setCopySuccess(false), 1000);
      })
      .catch(err => {
        // Handle error
        console.error("Failed to copy text: ", err);
      });
  };


  const handleSubmit = async () => {
      const combinedInput = `${text}\n${additionalText}`;
      //console.log("Combined input: ", combinedInput);

      try {
        //console.log("Sending POST request to /govex/aichat");
        const response = await fetch(`${BASE_URL}/govex/aichat`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ input: combinedInput })
        });

        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        //console.log("Response received, starting to process stream");
        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        // Process the stream
        let completeResponse = '';
        while (true) {
          const { value, done } = await reader.read();
          if (done) {
            //console.log("Stream complete");
            break;
          }
          const chunk = decoder.decode(value, { stream: true });
          completeResponse += chunk;
          if (chunk) {
            setChatResponse(completeResponse);
          }
          //console.log("Chunk received:", chunk);
        }
        //console.log("Complete response received:", completeResponse);
        // Handle the complete response if needed
      } catch (error) {
        console.error('Error communicating with ChatGPT:', error);
      }
  };

  const handleKeyDown = (event) => {
    // Check if the Enter key was pressed
    if (event.key === 'Enter' && !event.shiftKey) { // Prevent triggering on Shift+Enter
      event.preventDefault(); // Prevent the default action to avoid a new line in the textarea
      handleSubmit(); // Call the submit function
    }
  };





  if (!isOpen) return null;

  const handleAdditionalTextChange = (e) => {
    setAdditionalText(e.target.value);
  };

  const handleResponseTextChange = (e) => {
    setChatResponse(e.target.value);
  };

  return (
  <>
    <button className={`toggle-button-ai ${!isAICollapsed ? 'toggle-button-ai-collapsed' : ''}`} onClick={toggleAISidebar}>
      <span className="ai-icon">&#11166;</span> {/* Unicode icon for menu toggle */}{/* Adjust Unicode icons as needed */}
    </button>


    <div className={`AIsidebar ${isAICollapsed ? 'collapsed' : ''}`}>

        <div className="sidebar-header">{isAICollapsed ? 'AI' : 'AI Assistant'}</div>

        <div
          className={`chat-response-textarea ${isAICollapsed ? 'collapsed' : ''} ${!chatResponse ? 'placeholder' : ''}`}
        >
          <button
            className={`copyButton ${isAICollapsed ? 'collapsed' : ''} ${copySuccess ? 'copy-success' : ''}`}
            onClick={handleCopyToClipboard}
            aria-label="Copy"
          >
          </button>


          {chatResponse || "Hi! How can I assist you today?"}
        </div>


        <div className={`highlighted-text-display ${isAICollapsed ? 'collapsed' : ''}`}>
          <div className="highlighted-text-label">Our AI will use this highlighted text:</div>
          <div className="highlighted-text-content">{text}</div>
        </div>

        <div className={`additional-notes-container ${isAICollapsed ? 'collapsed' : ''}`}>
          <textarea
            className="additional-notes-textarea"
            placeholder="What would you like to do with this?"
            onChange={handleAdditionalTextChange}
            onKeyDown={handleKeyDown} // Add the onKeyDown event handler here
            value={additionalText}
          />
          <button onClick={handleSubmit}>Submit</button>
        </div>

      </div>
  </>
  );

};

export default AISidebar;



import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createTextNode, $getRoot } from 'lexical';
import { $createCodeNode, $isCodeNode } from '@lexical/code';
import { $convertFromMarkdownString, $convertToMarkdownString } from '@lexical/markdown';
import { TRANSFORMERS } from '@lexical/markdown';
import React, { useEffect } from 'react';

export default function ConvertFirstDraftFromMarkdown({ outlineMarkdown }) {
   const [editor] = useLexicalComposerContext();

   const convertFromMarkdownToNode = () => {
      editor.update(() => {
         const root = $getRoot();
         root.clear(); // Clear existing content

         $convertFromMarkdownString(outlineMarkdown, TRANSFORMERS);
      });
   };

   //const updateEditorContent = () => {
   //   editor.update(() => {
   //      const root = $getRoot();
   //      root.clear(); // Clear existing content
   //      const paragraphNode = $createParagraphNode();
   //      paragraphNode.append($createTextNode(newText));
   //      root.append(paragraphNode);
   //   });
   //};

   useEffect(() => {
      if (outlineMarkdown) {
         convertFromMarkdownToNode();
      }
   }, [outlineMarkdown, editor]);

   return null;
};

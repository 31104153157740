// SimpleModal.js
import React from 'react';
import './SimpleModal.css';

const SimpleModal = ({ onClose }) => (
    <div className="modalBackground">
        <div className="modalContainer">
            <h1>Welcome to GovEx AI</h1>
            <p>Check your email for a link to create a password.</p>
            <p className="emailCheckMessage">If you can't find the email, <span className="spamHighlight">check your spam!</span></p>
            <p>Once you've updated your password, you can then login to the dashboard</p>
            <button onClick={onClose}>Close</button>
        </div>
    </div>
);

export default SimpleModal;

import React, { useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams if you're using React Router for routing
import './ReferralLandingPage.css';

const PricingModelPage2= () => {

  useEffect(() => {
    const meta = document.createElement('meta');
    meta.name = 'robots';
    meta.content = 'noindex';
    document.head.appendChild(meta);

    // Cleanup the effect by removing the meta tag when the component unmounts
    return () => {
      document.head.removeChild(meta);
    };
  }, []);

  // Assuming you're using React Router, 'referralCode' will match the URL parameter
  let { referralCode } = useParams();

  const [pricingPeriod, setPricingPeriod] = useState('annual');
  const [selectedTokenBundle, setSelectedTokenBundle] = useState('one_token');
  const [showTrial, setShowTrial] = useState(true); // State to control the visibility of the "Trial" card

  const prices = {
    entry: {
      monthly: 1999, //price id: price_1Ogfy6CaD963jWWCdksrjy5N
      annual: 11999, //price id: price_1OgfzDCaD963jWWCP6YX9adp
    },
    trial: {
      monthly: 499 //price id: price_1OgfkYCaD963jWWCnhYZM4fU
    },
    token_bundle: {
      one_token: 749, //price id: price_1Ogfq5CaD963jWWCyS7Z34K9
      three_token: 1999, //price id: price_1OgfqsCaD963jWWCTqWx2bNp
      five_token: 2999 //price id: price_1OgfrNCaD963jWWC0X4h68tx
    },
    innovator: {
      monthly: 499, //price id: price_1OgfrNCaD963jWWC0X4h68tx
      annual: 2999, //price id: price_1OgfrNCaD963jWWC0X4h68tx
    }
  };

  const getProductTier = (tokenBundle) => {
    switch (tokenBundle) {
      case 'one_token':
        return 'Starter Tier';
      case 'three_token':
        return 'Lite Tier';
      case 'five_token':
        return 'Essential Tier';
      default:
        return 'Starter Tier'; // Default value if none match
    }
  };

  const ENV = process.env.NODE_ENV; // 'test' or 'live'

  // Select the URL based on the environment
  const BASE_URL = window.location.hostname === 'localhost' ? process.env.REACT_APP_TEST_URL : process.env.REACT_APP_LIVE_URL;

  return (
    <div className="referral-landing-page">
      <div className="pricing-section" id="Pricing">
        <div className="pricing-section-text-div">
        <h2 className="pricing-section-title">Experience <span className="pricing-section-smart" onClick={() => setShowTrial(!showTrial)}>smarter</span> proposal writing</h2>
    <span className="pricing-section-span">Start your trial today</span>

        </div>
        {/*
        <div className="pricing-period-tabs">
          <button
            className={`billing-cycle-option ${pricingPeriod === 'monthly' ? 'selected' : ''}`}
            onClick={() => setPricingPeriod('monthly')}>
            Monthly
          </button>
          <button
            className={`billing-cycle-option ${pricingPeriod === 'annual' ? 'selected' : ''}`}
            onClick={() => setPricingPeriod('annual')}>
            Annual
          </button>
        </div>
        */}
        <div className="pricing-cards-container">

          {showTrial && (
            <div className="pricing-card growth popular">
              <h3 className="h3-trial">Trial</h3>
              <div className="price-container">
                <p className="price">
                  {`$${prices.trial.monthly.toLocaleString()}`}
                </p>
              </div>
              <ul className="pricing-features-list">
                <li>Access to all features for 30 days</li>
                <li>Trial period begins when user first logs into the dashboard</li>
                <li>Limited to two proposal tokens</li>
                <li>24/7 Customer Support</li>
              </ul>
              <form action={`${BASE_URL}/create-checkout-session-trial`} method="POST">

                <input type="hidden" name="dbs" value={1} />
                <input type="hidden" name="grantTokens" value={0} />
                <input type="hidden" name="rfxTokens" value={0} />
                <input type="hidden" name="billingCycle" value={"once"} />
                <input type="hidden" name="productTier" value="Trial Tier" />
                <button className="start-trial-popular" type="submit">Start Trial ➾</button>
              </form>
            </div>
          )}

          {/* Commented out other cards */}
          {/*
          <div className="pricing-card innovator">
            <h3 className="h3-innovator">Innovator Tier</h3>
            <div className="price-container">
              <p className="price">
                {pricingPeriod === 'monthly' ?
                  `$${prices.innovator.monthly.toLocaleString()}/mo` :
                  <>
                    $249/mo
                  </>
                }
              </p>

              {pricingPeriod === 'annual' && (
                <p className="savings">
                  Save ${(prices.innovator.monthly * 12 - prices.innovator.annual).toLocaleString()} (6 months free) with annual billing
                </p>
              )}
            </div>

            <ul className="pricing-features-list">
              <li>All features of Base Tier</li>
              <li>Advanced Analytics</li>
              <li>Priority Support</li>
              <li>Custom Integrations</li>
              <li>Dedicated Account Manager</li>
            </ul>
            <form action={`${BASE_URL}/create-checkout-session`} method="POST">
              <input type="hidden" name="productTier" value="innovator" />
              <input type="hidden" name="billingCycle" value={pricingPeriod} />
              <input type="hidden" name="referralCode" value={referralCode} />
              <button className="start-trial-popular" type="submit">Get Started ➾</button>
            </form>
          </div>

          <div className="pricing-card growth popular">
            <div className="most-popular-tag">MOST POPULAR</div>
            <h3 className="h3-most-popular">Base Tier</h3>
            <div className="price-container">
              <p className="price">
                {pricingPeriod === 'monthly' ?
                  `$${prices.entry.monthly.toLocaleString()}/mo` :
                  <>
                    $999/mo
                  </>
                }
              </p>


              {pricingPeriod === 'annual' && (
                <p className="savings">
                  Save ${(prices.entry.monthly * 12 - prices.entry.annual).toLocaleString()} (6 months free) with annual billing
                </p>
              )}
            </div>

            <ul className="pricing-features-list">
              <li>RFP/RFI Module</li>
              <li>SBIR/STTR/FOA/BAA Intelligent Solicitation Analysis Tool</li>
              <li>SBIR/STTR/FOA/BAA Outline Extractor</li>
              <li>SBIR/STTR/FOA/BAA Targeted Topic Area Proposal Generator</li>
              <li>Compliance Matrix Generator</li>
              <li>Enhanced Outline Generator</li>
              <li>Advanced First Draft Generator</li>
              <li>Secure, Personal Database</li>
              <li>24/7 Customer Support</li>
            </ul>
            <div className="conic"></div>
            <form action={`${BASE_URL}/create-checkout-session`} method="POST">
              <input type="hidden" name="productTier" value="entry" />
              <input type="hidden" name="billingCycle" value={pricingPeriod} />
              <input type="hidden" name="referralCode" value={referralCode} />
              <button className="start-trial-popular" type="submit">Get Started ➾</button>
            </form>
          </div>

          <div className="pricing-card token-bundles">
            <h3 className="h3-token-bundles">Token Bundles</h3>
            <div className="price-container">
              <select
                className="token-select"
                value={selectedTokenBundle}
                onChange={(e) => setSelectedTokenBundle(e.target.value)}
              >
                <option value="one_token">1 Token - ${prices.token_bundle.one_token.toLocaleString()}</option>
                <option value="three_token">3 Tokens - ${prices.token_bundle.three_token.toLocaleString()}</option>
                <option value="five_token">5 Tokens - ${prices.token_bundle.five_token.toLocaleString()}</option>
              </select>
            </div>
            <ul className="pricing-features-list">
              <li>Purchase tokens for additional services</li>
              <li>Tokens can be used for one-time features</li>
              <li>Never expire</li>
              <li>24/7 Customer Support</li>
            </ul>
            <form action={`${BASE_URL}/create-checkout-session`} method="POST">
              <input type="hidden" name="productTier" value={getProductTier(selectedTokenBundle)} />
              <input type="hidden" name="billingCycle" value="once" />
              <input type="hidden" name="referralCode" value={referralCode} />
              <button className="start-trial-popular" type="submit">Purchase Tokens ➾</button>
            </form>
          </div>
          */}

        </div>
      </div>
    </div>
  );
};

export default PricingModelPage2;

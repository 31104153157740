import React, { useState, useEffect } from "react";
import axios from "axios"; // Ensure axios is installed via npm
import "./NewsletterPopup.css";

const NewsletterPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const BASE_URL = window.location.hostname === 'localhost' ? process.env.REACT_APP_TEST_URL : process.env.REACT_APP_LIVE_URL;

  useEffect(() => {
    const isPopupClosed = localStorage.getItem("newsletterPopupClosed");

    if (!isPopupClosed) {
      const timer = setTimeout(() => {
        setIsOpen(true);
      }, 6000); // 10-second delay

      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, []);

  const closePopup = () => {
    setIsOpen(false);
    localStorage.setItem("newsletterPopupClosed", "true");
    console.log("closed");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const response = await axios.post(`${BASE_URL}/subscribe`, { // Update the URL to match your Flask server
        email: email,
      });

      if (response.status === 201) {
        setSuccess(true);
        console.log("Email submitted:", email);
        localStorage.setItem("newsletterPopupClosed", "true");
        setIsOpen(false);
      } else {
        setError("Failed to subscribe. Please try again.");
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
      console.error(err);
    }
  };


  return (
    <>
      {isOpen && (
        <div className="popup-container">
          <div className="popup-content">
            <button className="close-btn" onClick={closePopup}>
              &times;
            </button>
            <h2>Subscribe to Our Newsletter</h2>
            <p>Stay updated with the latest news and exclusive content.</p>
            {success ? (
              <p>Thank you for subscribing!</p>
            ) : (
              <form onSubmit={handleSubmit}>
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button type="submit" className="subscribe-btn">
                  Subscribe
                </button>
              </form>
            )}
            {error && <p className="error-message">{error}</p>}
          </div>
        </div>
      )}
    </>
  );
};

export default NewsletterPopup;

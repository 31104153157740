import React, { useRef } from 'react';
import emailjs from 'emailjs-com'; // Import emailjs-com
import "./Contact.css";

function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_jf9lj2a', 'template_4a92v1e', form.current, 'FHPMQyBAuraKY0mLQ') // Replace with your service, template, and user IDs
      .then(
        (result) => {
          console.log(result.text);
          alert('Email sent successfully');
        },
        (error) => {
          console.log(error.text);
          alert('Error sending email');
        }
      );
  };

  return (
    <section className="contact-section">
      <h2 className="contact-heading">Contact Us</h2>
      <p className="contact-text">Have questions? Reach out to us for more information.</p>
      <form ref={form} onSubmit={sendEmail} className="contact-form">
        <label htmlFor="user_name" className="contact-label">
          <p>Name: </p>
          <input type="text" id="user_name" name="user_name" className="contact-input" />
        </label>
        <label htmlFor="user_email" className="contact-label">
          <p>Email: </p>
          <input type="email" id="user_email" name="user_email" className="contact-input" />
        </label>
        <label htmlFor="message" className="contact-label">
          <p>Message: </p>
          <textarea id="message" name="message" className="contact-textarea"></textarea>
        </label>
        <button type="submit" className="contact-button">
          Send
        </button>
      </form>
    </section>
  );
}

export default Contact;

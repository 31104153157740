import React from 'react';
import './TermsAndConditionsAndPrivacyPolicy.css'; // Import your CSS file for styling

function TermsAndConditionsAndPrivacyPolicy() {
  return (
    <div className="policy-container">

      <div className="policy-section introduction-section">
        <h2 className="policy-title">Introduction</h2>
        <p className="policy-text">
          Welcome to GovEx AI LLC. We offer a Software as a Service (SaaS) platform that automates the generation of proposals for uploaded Requests for Proposals (RFPs), primarily serving small businesses involved in government contracting. By accessing and utilizing our services, you (hereinafter referred to as "User") agree to comply with and be bound by the following Terms and Conditions and acknowledge our Privacy Policy. If you do not agree to these terms, please do not use our services.
        </p>
      </div>

      <div className="policy-section terms-section">
        <h2 className="policy-title">Terms and Conditions</h2>
        <p className="policy-text">
          These Terms and Conditions ("Terms") govern your access to and use of the services provided by GovEx AI LLC ("we", "us", "our"). By accessing or using our services, you agree to be bound by these Terms and all terms incorporated by reference. It is your responsibility to review these Terms periodically for changes.
        </p>
        <p className="policy-text">
          You agree to provide accurate and complete information when creating an account and using our services. You are solely responsible for your actions and any content you provide through our platform. Payments for our services are processed through Stripe; we collect your name, email, and company information for account creation and service provisioning.
        </p>
        <p className="policy-text">
          We reserve the right to modify, suspend, or discontinue our services at any time without notice. In the event of a violation of these Terms, we reserve the right to suspend or terminate your access to our services.
        </p>
        <p className="policy-text">
          We will notify users of any significant changes to these terms via email or through a prominent notice on our website. Continued use of our services following such modifications constitutes acceptance of the new Terms.
        </p>
      </div>

      <div className="policy-section privacy-section">
        <h2 className="policy-title">Privacy Policy</h2>
        <p className="policy-text">
          Your privacy is paramount to us. This Privacy Policy outlines our practices concerning the collection, use, and disclosure of your personal information. By using our services, you consent to the data practices described in this policy. We collect personal information such as names and emails through Stripe for account creation and service provision. We also use Google Analytics to understand how our services are used.
        </p>
        <p className="policy-text">
          We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except as described in this policy. You may review, update, or modify your personal information at any time. We employ various security measures to protect the safety of your personal information.
        </p>
        <p className="policy-text">
          We reserve the right to update or change our Privacy Policy at any time. Such changes will be effective immediately upon posting on our website. We will notify users of any significant changes via email or through a prominent notice on our website.
        </p>
      </div>

    </div>
  );
}

export default TermsAndConditionsAndPrivacyPolicy;

import React, { Component } from 'react';
import axios from 'axios';
import ArticlePreview from './ArticlePreview';
import './Blog.css'; // Import your CSS file for styling

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
    };
  }

  componentDidMount() {
    axios
      .get(
        'https://public-api.wordpress.com/rest/v1.1/sites/govexai.wordpress.com/posts'
      )
      .then((res) => {
        this.setState({ posts: res.data.posts });
      })
      .catch((error) => console.log(error));
  }

  render() {
    return (
      <div className="blog">
        <h1 className="BlogTitle">GovEx AI Blog</h1>
        <div className="articlePreviewsDiv">
          {this.state.posts.map((post) => (
            <ArticlePreview key={post.ID} post={post} />
          ))}
        </div>
      </div>
    );
  }

}

export default Blog;

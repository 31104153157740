import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrain, faBullseye, faUpload, faSitemap, faMagic, faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import './OutlineGenerator.css'; // Make sure the CSS file path is correct

function OutlineGenerator() {
  return (
  <div className="outline-generator-wrapper">
    <div className="outline-generator">
      <div className="generator-content">
        <div className="generator-text">
          <h1>GovEx AI Outline Generator</h1>
          <p>
            Specialized tool to create structured outlines for your proposals, tailored to the specific requirements of RFPs, RFIs, and RFQs.
          </p>
          <p>
            Utilize your company's content and our AI's understanding of industry standards to generate the most effective proposal outlines.
          </p>
          <a href="/contact" className="learn-more-button-Generator">Contact for more information</a>
        </div>
        <div className="generator-image">
          <img src="/images/govex_outline_generator.png" alt="GovEx AI Outline Generator" />
        </div>
        <div className="generator-benefits">
            <h2>Efficient Outline Creation</h2>
            <p>
                Upload the document and let GovEx AI analyze its requirements. Our tool immediately starts working on creating a structured outline.
            </p>

            <h2>Customized for Your Needs</h2>
            <p>
                The Outline Generator customizes each outline using your company’s specific content and expertise, ensuring relevance and precision.
            </p>

            <h2>Ready-to-Use Framework</h2>
            <p>
                Receive a comprehensive, easy-to-follow outline that seamlessly integrates with your proposal writing process, enhancing both efficiency and effectiveness.
            </p>
        </div>
      </div>

      {/* Call to Action Buttons */}
      <div className="buttons">
        <a href="/contact" className="learn-more-button">Explore Outline Generator</a>
      </div>

    </div>
  </div>
  );
}

export default OutlineGenerator;

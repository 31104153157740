import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faChartLine, faFileAlt, faSyncAlt, faCloud, faUsers, faBrain, faLock, faSearch, faCheckCircle, faTools, faUserFriends, faGlobeAmericas, faLanguage, faMobileAlt, faDesktop, faCogs, faPalette } from '@fortawesome/free-solid-svg-icons';
import './GovExProposal.css'; // Make sure the CSS file path is correct

function GovExProposal() {
  return (
  <div className="govex-proposal-wrapper">
    <div className="govex-proposal">
      <div className="proposal-content">
        <div className="proposal-text">
          <h1>GovEx AI Proposal Writer</h1>
          <p>
            AI-driven proposal writing tool designed to streamline RFP, RFI, and RFQ responses with efficiency and precision.
          </p>
          <p>
            Craft winning proposals with the power of AI, reducing manual effort and increasing success rates.
          </p>
          <a href="/contact" className="learn-more-button-Proposal">Talk to our team</a>
        </div>
        <div className="proposal-image">
          <img src="/images/govEx_logo.png" alt="GovEx AI Proposal Writer" />
        </div>
        <div className="proposal-benefits">
            <h2>Streamlined Proposal Creation with GovEx AI</h2>
            <p>
                Begin by simply uploading the RFP, RFI, or RFQ document you're responding to. Our intuitive platform makes this step quick and straightforward.
            </p>
            <p>
                Within minutes, GovEx AI provides you with a comprehensive outline for your proposal, accompanied by a professional draft. This draft serves as a solid foundation for your final submission, significantly reducing your workload and preparation time.
            </p>

            <h2>Transform Proposal Writing with GovEx AI</h2>
            <p>
                Discover the ease of crafting tailored proposals with GovEx AI. Our tool simplifies complex writing tasks, saving time and enhancing accuracy.
            </p>
            <p>
                With GovEx AI, respond to RFPs, RFIs, and RFQs more effectively. Experience a boost in productivity and a higher win rate in your proposal submissions.
            </p>

            <h2>Seamless Process from Start to Finish</h2>
            <p>
                GovEx AI is designed for swift and simple setup. Get started immediately with no complex integration or lengthy training required.
            </p>
            <p>
                Thanks to our streamlined process, you can upload an RFP, RFI, or RFQ and receive a well-crafted proposal draft on the very same day, enabling prompt submissions and a competitive edge.
            </p>
        </div>


      </div>

      {/* Call to Action Buttons */}
      <div className="buttons">
        <a href="/contact" className="learn-more-button">Get a demo from our team</a>
      </div>

    </div>
  </div>
  );
}

export default GovExProposal;

import React, { useState, useRef } from 'react';
import LoadingBar from 'react-top-loading-bar';

function GenerateProposalStructure({ onProposalStructureCreated, apiKey, currentComplianceMatrix, setSelectedTab, setProposalStructureStreamed, complianceMatrixCreated, setProposalStructureStreaming, selectedFile }) {
  const [isLoading, setIsLoading] = useState(false);
  const loadingBarRef = useRef(null);

  const ENV = process.env.NODE_ENV; // 'test' or 'live'

  // Select the URL based on the environment
  const BASE_URL = window.location.hostname === 'localhost' ? process.env.REACT_APP_TEST_URL : process.env.REACT_APP_LIVE_URL;

  const handleGenerateProposalStructure = async () => {
    setIsLoading(true);
    setProposalStructureStreamed(''); // Resetting streamed content state
    setProposalStructureStreaming(true);
    setSelectedTab('viewProposalStructure'); // Update UI to display the proposal structure
    if (loadingBarRef.current) {
      loadingBarRef.current.continuousStart(); // Start loading animation
    }

    try {

        const formData = new FormData();
        formData.append('complianceMatrix', JSON.stringify({ complianceMatrix: currentComplianceMatrix }));
        formData.append('selectedFile', selectedFile);

        //console.log("Sending POST request to /govex/generate-proposal-structure");
        const response = await fetch(`${BASE_URL}/govex/generate-proposal-structure`, {
            method: 'POST',
            headers: {
                'X-API-KEY': apiKey,
                //'Content-Type': 'application/json',
            },
            body: formData, // Send data as JSON
        });

        if (!response.ok) {
            throw new Error(`Response was not ok: ${response.statusText}`);
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        let proposalStructureContent = '';

        while (true) {
            const { value, done } = await reader.read();
            if (done) {
                console.log("Streaming complete");
                break;
            }
            const chunk = decoder.decode(value, { stream: true });
            //console.log("Chunk received:", chunk);
            proposalStructureContent += chunk;
            setProposalStructureStreamed((prevContent) => prevContent + chunk); // Dynamically update the proposal structure content
        }


        // Now, send the complete proposal content to finalize the proposal
        console.log(proposalStructureContent);
        const finalizeResponse = await fetch(`${BASE_URL}/govex/finalize-proposal-structure`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ finalizedProposalStructure: proposalStructureContent }),
        });

        console.log("Fetch request sent.");

        if (!finalizeResponse.ok) {
            throw new Error(`Finalize response was not ok: ${finalizeResponse.statusText}`);
        }

        const finalizeData = await finalizeResponse.json();
        //console.log("Finalize response received:", finalizeData);

        // Handle the response, e.g., updating state or UI based on finalizeData
        onProposalStructureCreated(finalizeData.finalizedProposalStructure);



    } catch (error) {
        console.error('Error generating proposal structure:', error);
    } finally {
        if (loadingBarRef.current) {
            loadingBarRef.current.complete(); // End loading animation
        }
        setIsLoading(false); // Reset loading state
    }
  };

  return (
    <div>
      <button onClick={handleGenerateProposalStructure} disabled={isLoading || !complianceMatrixCreated}>
        {isLoading ? 'Generating...' : 'Generate Proposal Structure'}
      </button>
      <LoadingBar color="#f11946" ref={loadingBarRef} />
    </div>
  );
}

export default GenerateProposalStructure;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import './Article.css'; // Import your CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import NewsletterPopup from "./NewsletterPopup";

function Article() {
  const { articleId } = useParams();
  const [post, setPost] = useState(null);
  const [copySuccess, setCopySuccess] = useState('');
  const [copyError, setCopyError] = useState('');

  useEffect(() => {
    axios
      .get(`https://public-api.wordpress.com/rest/v1/sites/govexai.wordpress.com/posts/${articleId}`)
      .then((res) => {
        setPost(res.data);
      })
      .catch((error) => console.log(error));
  }, [articleId]);

  if (!post) {
    return <div>Loading...</div>;
  }

  const { title, content, featured_image, author, date } = post;

  const formattedDate = new Date(date).toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const copyArticleLink = () => {
    const url = window.location.href;

    try {
      // Create a text area element to hold the URL
      const textArea = document.createElement('textarea');
      textArea.value = url;
      document.body.appendChild(textArea);
      textArea.select();

      // Attempt to copy the URL to the clipboard
      const successful = document.execCommand('copy');
      document.body.removeChild(textArea);

      if (successful) {
        setCopySuccess('Link copied to clipboard!');
        setCopyError('');
      } else {
        setCopySuccess('');
        setCopyError('Copy failed. Please select the link and copy it manually.');
      }
    } catch (error) {
      setCopySuccess('');
      setCopyError('Copy failed. Please select the link and copy it manually.');
    }
  };

  const modifiedTitle = title
    .replace(/&#8217;/g, "'") // Replace "&#8217;" with a single apostrophe

  return (
    <div className="article">
      {featured_image && (
        <img
          className="img-responsive webpic"
          alt="article header"
          src={featured_image}
        />
      )}
      <h1 className="text-center article-title">{modifiedTitle}</h1>
      <p className="author-on-post">Author: {author.name}</p>
      <p className="publication-date-on-post">Published on {formattedDate}</p>
      <div className="share-links">
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`}
          target="_blank"
          className="share-link"
        >
          <FontAwesomeIcon icon={faFacebook} /> Share on Facebook
        </a>
        <a
          href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}`}
          target="_blank"
          className="share-link"
        >
          <FontAwesomeIcon icon={faTwitter} /> Share on Twitter
        </a>
        <a
          href="javascript:void(0)"
          onClick={copyArticleLink}
          className="share-link"
        >
          <FontAwesomeIcon icon={faCopy} /> Copy Link
        </a>
      </div>
      <div className="article-content" dangerouslySetInnerHTML={{ __html: content }} />
      <Link to="/blog">
        <button className="return-button">Return to Blog</button>
      </Link>
      <NewsletterPopup />
    </div>
  );
}

export default Article;

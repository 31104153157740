import './HowToUseGovExAI.css';
function HowToUseGovExAI() {
  return (
    <div className="guideContainer">
      <h2>How to Use GovEx AI Proposal Writer</h2>
      <ol className="guideSteps">
        <li>
          <h3>Step 1: Select a Workflow</h3>
          <p><strong>Starting Point:</strong> Navigate to the GovEx AI Proposal Writer platform and locate the "Workflow" option on the dashboard.</p>
          <p><strong>Action:</strong> You have two choices here:</p>
          <ul>
            <li><strong>Create a New Workflow:</strong> Select this to start a fresh proposal process. Give your workflow a name and click Create New Workflow.</li>
            <li><strong>Choose an Existing Workflow:</strong> If you've previously worked on a proposal, select it from the list to continue where you left off.</li>
          </ul>
        </li>
        <li>
          <h3>Step 2: Manage Database</h3>
          <p><strong>Accessing the Database:</strong> Find the "Manage Database" section within the dashboard</p>
          <p><strong>Purpose:</strong> This area allows you to add or remove documents that the AI will reference when generating your proposal.</p>
          <p><strong>Actions:</strong></p>
          <ul>
            <li><strong>Add Documents:</strong> Upload new documents that are relevant to your proposal. These can include previous proposals, technical documents, or any other materials you want the AI to reference.</li>
            <li><strong>Remove Documents:</strong> Delete any outdated or irrelevant documents from the database.</li>
          </ul>
        </li>
        <li>
          <h3>Step 3: Upload RFP/RFI/RFQ/SBIR and Create a Compliance Matrix</h3>
          <p><strong>Uploading Documents:</strong> Click on the upload button to add your RFP/RFI/RFQ/SBIR.</p>
          <p><strong>Compliance Matrix Creation:</strong> Click the "Generate Compliance Matrix" button. Upon uploading, the AI will scan the document to create a compliance matrix. This matrix outlines all the requirements and specifications mentioned in the document, ensuring your proposal meets all necessary criteria.</p>
        </li>
        <li>
          <h3>Step 4: Generate Proposal Structure</h3>
          <p><strong>Activation:</strong> Once the Complicane Matrix is done being generated, click on the "Generate Proposal Structure" button.</p>
          <p><strong>Purpose:</strong> The AI examines the compliance matrix and proposes a structure for your proposal, including a table of contents for each proposed volume.</p>
        </li>
        <li>
          <h3>Step 5: Edit Proposal Volume's Table of Contents</h3>
          <p><strong>Editing Process:</strong> You have the option to fine-tune the table of contents for each volume.</p>
          <p><strong>Making Changes:</strong> Press the edit button at the bottom of a volume you want to edit. This will take you to the section where you can make changes to the volume's table of contents.</p>
          <p><strong>Saving Changes:</strong> Make sure to save your edits by pressing the save button located at the top right corner of the editor. This ensures that your workflow is updated, allowing you to resume work seamlessly in future sessions.</p>
        </li>
        <li>
          <h3>Step 6: Create Draft Based on Outline</h3>
          <p>Once you are happy with the outline/table of contents for your volume. Click on the "Create Draft Based on This Outline" button to start the drafting process.</p>
          <p><strong>Watching the Draft Generate:</strong>You can observe the draft being created in real-time, be sure to stay on this page until the draft is done being generated.</p>
          <p><strong>Saving and Exporting:</strong>After the draft is complete, save your document and export it as a Word doc using the buttons located at the top right of the editor.</p>
        </li>
        <li>
          <h3>Step 7: Iterate as Needed</h3>
          <p>Revisit the proposal structure section to create drafts for different volumes as needed.</p>
        </li>
      </ol>
    </div>
  );
}
export default HowToUseGovExAI;
import React, { useState } from 'react';
import './Products.css';

function Products() {
  const productData = [
    {
      name: 'Federal Frontier Application Platform',
      image: '/images/Federal_Frontier-logo-transparent.png',
      description: 'Elevate your application deployments with Federal Frontier Kubernetes Grid (FKG). A multi-cluster Kubernetes solution that simplifies management, offers a wide array of platform services, and provides user-friendly interfaces for rapid deployment, enhanced stability, and efficiency. FKG ensures centralized control, streamlining cluster deployment, management and monitoring with various Kubernetes distributions, while enabling easy access to essential services and partner add-ons.',
      link: '/federal-frontier'
    },
    {
      name: 'Federal Frontier FleetEdge',
      image: '/images/ff_edge_big_2_transparent.png',
      description: 'Elevate your Kubernetes deployment with Federal Frontier Kubernetes Grid (FKG) by Eupraxia Labs—an innovative, CNCF-approved multi-cluster Kubernetes solution that simplifies management, offers a wide array of platform services, and provides a user-friendly interface for rapid deployment, enhanced stability, and efficiency. FKG Enterprise ensures centralized control, streamlining cluster management and compatibility with various Kubernetes distributions, while enabling easy access to essential services and partner add-ons.',
      link: '/frontier-edge'
    },
    {
      name: 'XtremeCloud Single Sign-On',
      image: '/images/XCSSO-logo-1-transparent.png',
      description: 'XtremeCloud Single Sign-On (SSO) is a robust identity and access management (IAM) platform, offering comprehensive single sign-on (SSO) capabilities across multiple cloud environments and data centers. It empowers businesses with multi-cloud deployment options, efficient load balancing, and seamless integration with major Cloud Service Providers (CSPs) for resiliency. With a focus on flexibility, XtremeCloud SSO supports OpenID Connect (OIDC) and SAML 2.0 protocols, allowing clients and services to be secured according to specific needs. It offers adaptable adapters for various platforms, enhancing security for Kubernetes applications across both single-tenant and multi-tenant deployments.',
      link: '/xtreme-cloud-sso'
    }
  ];


  const [selectedProduct, setSelectedProduct] = useState(productData[0]);

  const handleProductChange = (product) => {
    setSelectedProduct(product);
  };

  return (
    <div className="products-page">
      <section className="overview-section">
        <div className="overview-content">
          <h1>Unlock the Power of Cloud Solutions</h1>
          <p>Experience innovation and efficiency with our application platform.</p>
        </div>
      </section>
      <div className="quick-look-steps">
         <div className="quick-look-step">
           <img src="/images/innovation-art.png" alt="Rapid Deployment" />
           <p>Rapid Deployment</p>
         </div>
         <div className="quick-look-step">
           <img src="/images/shield-art.png" alt="Reliability" />
           <p>Ensuring Stability</p>
         </div>
         <div className="quick-look-step">
           <img src="/images/lock-art.png" alt="Security" />
           <p>Establishing Security</p>
         </div>
         <div className="quick-look-step">
           <img src="/images/revenue-art.png" alt="Return on Investment" />
           <p>Rapid Return on Investment</p>
         </div>
      </div>

      <div className="product-tabs">
        {productData.map((product) => (
          <div
            key={product.name}
            className={`product-tab ${product.name === selectedProduct.name ? 'active' : ''}`}
            onClick={() => handleProductChange(product)}
          >
            {product.name}
          </div>
        ))}
      </div>

      <section className="quick-look-section">
        <div className="quick-look-content">
          <div className="quick-look-image">
            <img src={selectedProduct.image} alt={selectedProduct.name} />
          </div>
          <div className="quick-look-text">
            <a
              href={selectedProduct.link}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <h2>{selectedProduct.name}</h2>
            </a>
            <p>{selectedProduct.description}</p>
            <a href={selectedProduct.link} className="learn-more-button">Learn More</a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Products;
